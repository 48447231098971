<template>
    <div class="body">
        <!--导航栏--->
        <section class="top">
            <van-nav-bar title="申请换货" left-arrow :fixed='isTop' @click-left="$router.back(-2)" />
        </section>
        <!-- <span>{{ JSON.stringify(orderInfo) }}</span> -->
        <!-- 商品信息 -->
        <section class="shopInfo">
            <div class="shopName">
                <van-image round width="0.6rem" height="0.6rem" :src="orderInfo.imgUrl" />
                <span>{{ orderInfo.shopName }}</span>
                <!-- 点击跳转到店铺页面-->
                <van-icon name="arrow" size="0.4rem" @click="toShopDetail(orderInfo.shopId)" />
            </div>
            <div class="goodsInfo">
                <div class="goodsList">
                    <van-image width="80" height="80" fit="cover" :src="orderInfo.picUrl" />
                    <div class='goodsText'>
                        <div>
                            <p>{{ orderInfo.spuName }}
                            </p>
                        </div>
                        <div class="size fontColor">
                            <span>规格：{{ orderInfo.specInfo }}</span>
                            <van-stepper v-model="updateInfo.quantity" min="1" :max="orderInfo.quantity" />
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <!-- 换货原因 -->
        <section class="changeReason">
            <van-cell is-link @click="showPopup" title="换货原因">{{ updateInfo.refundReson }}</van-cell>
            <van-action-sheet v-model="show" title="换货原因" safe-area-inset-bottom :closeable='isShowClose'>
                <van-radio-group v-model="updateInfo.refundReson">
                    <van-radio name="货物破损" checked-color="#E54320" icon-size="0.6486rem">货物破损
                    </van-radio>
                    <van-radio name="颜色不满意" checked-color="#E54320" icon-size="0.6486rem">颜色不满意
                    </van-radio>
                    <van-radio name="尺寸不合适" checked-color="#E54320" icon-size="0.6486rem">尺寸不合适
                    </van-radio>
                    <van-radio name="款式不满意" checked-color="#E54320" icon-size="0.6486rem">款式不满意
                    </van-radio>
                    <van-radio name="卖家发错货了" checked-color="#E54320" icon-size="0.6486rem">卖家发错货了
                    </van-radio>
                </van-radio-group>
                <van-button block round type="info" color="#E54320" @click="submitChange">提交</van-button>
            </van-action-sheet>
        </section>
        <!-- 换货补充 -->
        <section class="supplement">
            <p>补充描述和凭证</p>
            <van-field v-model="updateInfo.resonDescribe" type="textarea" placeholder="补充描述，有助于商家更好地处理售后问题" show-word-limit
                maxlength="200" />
            <van-uploader v-model="fileList" :max-count="6" multiple accept="image/*" :before-delete="deletePics"
                :after-read="afterRead" />
        </section>
        <!-- 提交 -->
        <section class="submitBtn">
            <van-tabbar :fixed="isTop">
                <van-button round block type="primary" color="#E54320" @click="sendInfo">提交</van-button>
            </van-tabbar>
        </section>
    </div>
</template>

<script>
import { applyChange } from '@/api/applyForChange/index'
import { Toast, Dialog } from 'vant'
import { upload } from '@/api/tsMallorderDetail'
export default {
    data() {
        return {
            isTop: true,//顶部导航栏
            orderInfo: {},//换货店铺信息
            listOrderItem: [],//换货商品信息
            show: false,//是否显示弹出框
            isShowClose: false,//是否显示弹出框关闭按钮
            fileList: [],//上传文件
            isSelectReason: false,//是否选择换货原因
            updateInfo: {
                orderId: '',//订单id
                itemId: '',
                quantity: 1,//换货数量
                resonDescribe: '',//补充凭证文本
                refundReson: '',//换货原因
                resonUrl: ''
            },
            imgArr: [],//上传图片列表
            timer: null,//清除定时器
        }
    },
    created() {
        console.log(JSON.parse(this.$route.query.data), '<===数据');
        this.orderInfo = JSON.parse(this.$route.query.data)
        this.updateInfo.orderId = JSON.parse(this.$route.query.data).orderId
        this.updateInfo.itemId = JSON.parse(this.$route.query.data).itemId
        if (JSON.parse(this.$route.query.data).types && JSON.parse(this.$route.query.data).saleId) {
            this.updateInfo.types = JSON.parse(this.$route.query.data).types
            this.updateInfo.saleId = JSON.parse(this.$route.query.data).saleId
            // Toast(`${this.$route.query.data}`)
        }
    },

    methods: {

        //点击展示弹出框
        showPopup() {
            this.show = true;
        },
        //点击提交显示换货原因
        submitChange() {
            this.show = !this.show;
            this.isSelectReason = true
        },
        //点击提交换货申请
        sendInfo() {

            if (this.updateInfo.refundReson) {
                this.updateInfo.resonUrl = this.imgArr.join(',')
                applyChange(this.updateInfo).then(res => {
                    if (res.data.code == 0) {
                        Toast.success('已申请')
                        this.timer = setTimeout(() => {
                            this.backToList()
                        }, 500);
                    } else {
                        Toast(res.data.message)
                    }
                }).catch(err => {
                    Toast.fail('申请失败，请重新申请', err)
                })
                //点击提交信息，并跳转到换货列表页面
            } else {
                Toast('请选择换货原因')
            }
        },
        //回退到换货列表
        backToList() {
            let u = navigator.userAgent;
            let isAndroid = u.indexOf("Android") > -1 || u.indexOf("Linux") > -1; //android
            let isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
            if (isIOS) {
                window.webkit.messageHandlers.flMallOrderRefund.postMessage("");
            }
            if (isAndroid) {
                window.android.flMallOrderRefund();
            }
        },
        //上传照片
        /*  afterRead(file) {
             // console.log(file, '上传')
             file.status = 'uploading'
             file.message = '上传中'
             let formData = new window.FormData()
             formData.append('file', file.file)
             formData.append('fileType', 'imgage/png'),
                 formData.append('dir', 'material')
             // console.log(formData, '参数哦')
             upload(formData).then(res => {
                 if (res.data.code === 0) {
                     file.status = ''
                     file.message = ''
                     this.imgArr.push(res.data.data)
                 }
             }).catch(() => {
                 file.status = 'failed',
                     file.message = '上传失败'
             })
         }, */
        afterRead(file, detail) {
            // 此时可以自行将文件上传至服务器
            console.log(file, '<===文件');
            console.log(detail, '<===detail文件');
            if (file.length > 1) {
                //使用for循环来适配多选情况
                for (let i in file) {
                    file[i].status = 'uploading'
                    file[i].message = '上传中'
                    this.upImg(file[i], detail)
                }
            } else {
                file.status = 'uploading'
                file.message = '上传中'
                this.upImg(file, detail)
            }
        },
        //上传图片封装
        upImg(file, detail) {
            console.log(detail, 'detail<==文件2');
            let formData = new window.FormData()
            formData.append('file', file.file)
            formData.append('fileType', 'imgage/png'),
                formData.append('dir', 'material')
            // console.log(formData, '参数哦')
            upload(formData).then(res => {
                if (res.data.code === 0) {
                    file.status = ''
                    file.message = ''
                    this.imgArr.push(res.data.data)
                }
            }).catch(() => {
                file.status = 'failed',
                    file.message = '上传失败'
            })
        },
        //删除照片
        deletePics(file, detail) {
            Dialog.confirm({
                title: '截图',
                message: '确认删除截图？'
            }).then(() => {
                this.fileList.splice(detail.index, 1)
                this.imgArr.splice(detail.index, 1)
            }).catch(() => {
                Toast.fail('已取消')
            })
        }
    },
    beforeDestroy() {
        clearInterval(this.timer);
        this.timer = null;
    }
}
</script>

<style lang="less" scoped>
.body {
    width: 100%;
    height: 100%;
    background: #F6F6F6;

    section {
        background: #fff;
    }

    .top {
        height: 6%;
        margin-bottom: 0.4324rem;

    }

    .shopInfo {
        margin-bottom: 0.4324rem;
        display: flex;
        flex-flow: column nowrap;
        padding: 0.4324rem 0.5189rem;

        .shopName {
            display: flex;
            flex-flow: row nowrap;
            justify-content: flex-start;
            align-items: center;

            span {
                margin: 0 0.4324rem;
                font-size: 0.4324rem;
                max-width: 6.4865rem;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }
        }

        .goodsInfo {
            display: flex;
            flex-flow: column nowrap;
            padding-top: 0.4324rem;

            .goodsList {
                display: flex;
                flex-flow: row nowrap;

                .goodsText {
                    margin-left: 0.5189rem;
                    display: flex;
                    flex: 1;
                    flex-flow: column nowrap;
                    justify-content: space-between;

                    p {
                        width: 6.4865rem;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                    }

                    .size {
                        display: flex;
                        justify-content: space-between;
                    }
                }
            }
        }

    }

    .changeReason {
        margin-bottom: 0.027rem;

        .van-action-sheet {
            box-sizing: border-box;
            padding: 0.173rem 0.4324rem 0.173rem 0.4324rem;

            .van-radio-group {
                padding: 0.4324rem 0.4324rem;

                .van-radio {
                    height: 1.2973rem;
                }
            }
        }
    }

    .supplement {
        padding: 0.4324rem;
        background: #F7F7F7;

        .van-field {

            background: #F7F7F7;
        }

        .van-uploader {
            height: 6rem;
            margin-left: 0.4rem;
            margin-top: 0.4rem;

            /deep/.van-uploader__upload {
                margin: 1px;
                border: 1px dashed #999999;
            }

        }
    }

    .submitBtn {
        // padding: 0.4324rem;
        width: 100%;
        padding: 0 10px;
        display: flex;
        justify-content: space-evenly;
        box-sizing: border-box;

        .van-tabbar {
            box-sizing: border-box;
            padding: 3px 10px;

            .van-button {
                width: 100%;
                height: 40px;
            }
        }
    }
}
</style>