import { fetchGet, fetchPost } from '@/router/https'

// 订单详情
const orderDetail = (id) => {
  return fetchGet('/mallapp/orderinfo/' + id)
}

const upload = (param) => fetchPost('/mallapp/feedbackrelease/uploadFile', param)

// 申请退款
const orderrefunds = (data) => {
  return fetchPost('/mallapp/orderrefunds', data)
}

// 取消订单
const cancalOrders = (data) => fetchPost('/mallapp/orderinfo/cancel', data)

// 实时查询物流
const queryExpress = (data) => fetchPost('/mallapp/orderinfo/queryExpress', data)

// 确认收货
const receive = (id) => fetchGet('/mallapp/orderinfo/receive/' + id)

// 删除订单
const deleteOrder = (params) => fetchGet('/mallapp/orderinfo/deleteOrder', params)

// 商品批量评价
const saveBatch = (data) => fetchPost('/mallapp/goodsappraises/saveBatch', data)

// 微信支付
const weixin = (data) => fetchPost('/mallapp/orderinfo/unifiedOrder', data)

// 支付宝支付
const zhifubao = (data) => fetchPost('/mallapp/pay/alipayBGS', data)

export {
  orderDetail,
  upload,
  orderrefunds,
  cancalOrders,
  queryExpress,
  receive,
  deleteOrder,
  saveBatch,
  weixin,
  zhifubao
}